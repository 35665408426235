export class Role {
  constructor ({
    id,
    name,
    group_id,
    permissions,
  } = {}) {
    this.id = id
    this.name = name
    this.group_id = group_id
    this.permissions = permissions
  }
}

export function createRole (data) {
  return Object.freeze(new Role(data))
}
