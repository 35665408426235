<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />

        <div v-if="ready">
          <v-row>
            <v-col
              cols="3"
              md="3"
            >
              <v-card-text
                class="mr-3 ml-6"
                height="100px"
                width="100px"
              >
                <v-treeview
                  :items="roles.filter(el => el.level >= ($user.get().roles[0].level + 1))"
                  activatable
                  :active.sync="active"
                  hoverable
                  return-object
                  transition
                />
              </v-card-text>
            </v-col>
            <v-divider vertical />
            <v-col>
              <v-card-text
                v-if="active[0]"
                class="mr-3 ml-6 pl-4 pr-4 pb-4"
                overflow
              >
                <v-row align="center">
                  <v-card-title> {{ active[0].name }} </v-card-title>
                  <v-btn
                    v-if="active[0].parent"
                    icon
                    small
                    title="Remover sub-grupo de acesso"
                    @click="
                      removeRole(active[0].id)
                    "
                  >
                    <v-icon color="red darken-3">
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  <role-form-dialog
                    v-if="active[0].level >= ($user.get().roles[0].level + 1) && !active[0].parent"
                    :grupo="active[0].id"
                  />
                </v-row>
                <v-row
                  v-if="active[0].permissoes"
                  class="mr-1"
                >
                  <v-card
                    v-for="permi in permissoes"
                    :key="permi.id"
                    class="pr-8 pl-4 pb-2 mb-2 mr-3 ml-3"
                  >
                    <v-card-title> {{ permi.group }} </v-card-title>
                    <v-card-text dense>
                      <v-checkbox
                        v-for="(item) in permi.permissions"
                        :key="item.id"
                        v-model="active[0].permissoes[item.id]"
                        dense
                        class="ml-2"
                        :label="item.description"
                        color="primary"
                        hide-details
                        :disabled="isCheckBoxDisabled(permi, item)"
                        @click="onCheckBox(permi, item)"
                      />
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-row
            class="pa-3"
            justify="center"
            justify-sm="end"
          >
            <v-btn
              class="text-none text-white ml-2"
              color="error"
              rounded
              @click="$router.push({ path: `/${$user.get().role}` })"
            >
              <v-icon
                dark
                left
              >
                mdi-minus-circle
              </v-icon>
              Cancelar
            </v-btn>
            <v-btn
              class="text-none text-white ml-2"
              color="success"
              rounded
              @click="SUBMIT"
            >
              <v-icon
                dark
                left
              >
                mdi-checkbox-marked-circle
              </v-icon>
              Salvar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </div>
</template>
<script>
  import RoleFormDialog from './RoleFormDialog'
  import UserStore, { BOOTSTRAP, SUBMIT, REMOVE_ROLE } from '@/store/modules/user'
  import { mapActions, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  export default {
    name: 'ControleAcesso',
    components: {
      RoleFormDialog,
    },
    data () {
      return {}
    },
    computed: {
      ...mapFields('role', ['active']),
      ...mapState('role', ['roles', 'itens', 'ready', 'permissoes']),
    },
    async created () {
      if (!this.$store.state.role) { this.$store.registerModule(['role'], UserStore) }
      this.BOOTSTRAP()
      this.selected_permissions = []
      this.active = []
    },
    methods: {
      ...mapActions('role', [BOOTSTRAP, SUBMIT, REMOVE_ROLE]),
      removeRole (id) {
        this.REMOVE_ROLE(id)
      },
      onCheckBox (group, item) {
        if (group.permissions.length && group.permissions[0].id === item.id && item.name.indexOf('list_', 0) >= 0) {
          if (!this.active[0].permissoes[item.id]) {
            for (let i = 1; i < group.permissions.length; i++) {
              this.active[0].permissoes[group.permissions[i].id] = false
            }
          }
        }
      },
      isCheckBoxDisabled (group, item) {
        let resultado = false
        if (group.permissions.length && group.permissions[0].name.indexOf('list_', 0) >= 0 && item.name.indexOf('list_', 0) < 0) {
          resultado = !this.active[0].permissoes[group.permissions[0].id]
        }
        return resultado
      },
    },
  }
</script>
